import React from 'react';
import PropTypes from 'prop-types';

const Component = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="rainbow-GlobalSearch-results"
                    transform="translate(-147.000000, -201.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                >
                    <g id="Group-22" transform="translate(87.500000, 0.000000)">
                        <g id="Group-20" transform="translate(30.500000, 172.000000)">
                            <g id="Group-2" transform="translate(17.000000, 16.000000)">
                                <g id="Group" transform="translate(12.000000, 13.000000)">
                                    <path
                                        d="M18.8944252,6.11362812e-13 C19.5050173,6.11362812e-13 20,0.494982713 20,1.10557483 L20,4.82021928 C20,5.4308114 19.5050173,5.92579411 18.8944252,5.92579411 L15.1797807,5.92579411 C14.5691886,5.92579411 14.0742059,5.4308114 14.0742059,4.82021928 L14.0742059,1.10557483 C14.0742059,0.494982713 14.5691886,6.11362812e-13 15.1797807,6.11362812e-13 L18.8944252,6.11362812e-13 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M11.8510939,6.11362812e-13 C12.461686,6.11362812e-13 12.9566687,0.494982713 12.9566687,1.10557483 L12.9566687,4.82021928 C12.9566687,5.4308114 12.461686,5.92579411 11.8510939,5.92579411 L8.13644947,5.92579411 C7.52585735,5.92579411 7.03087463,5.4308114 7.03087463,4.82021928 L7.03087463,1.10557483 C7.03087463,0.494982713 7.52585735,6.11362812e-13 8.13644947,6.11362812e-13 L11.8510939,6.11362812e-13 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M4.85936867,6.11362812e-13 C5.46996079,6.11362812e-13 5.9649435,0.494982713 5.9649435,1.10557483 L5.9649435,4.82021928 C5.9649435,5.4308114 5.46996079,5.92579411 4.85936867,5.92579411 L1.14472422,5.92579411 C0.534132104,5.92579411 0.0391493905,5.4308114 0.0391493905,4.82021928 L0.0391493905,1.10557483 C0.0391493905,0.494982713 0.534132104,6.11362812e-13 1.14472422,6.11362812e-13 L4.85936867,6.11362812e-13 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M18.8552758,6.98816621 C19.4658679,6.98816621 19.9608506,7.48314892 19.9608506,8.09374104 L19.9608506,11.8083855 C19.9608506,12.4189776 19.4658679,12.9139603 18.8552758,12.9139603 L15.1406313,12.9139603 C14.5300392,12.9139603 14.0350565,12.4189776 14.0350565,11.8083855 L14.0350565,8.09374104 C14.0350565,7.48314892 14.5300392,6.98816621 15.1406313,6.98816621 L18.8552758,6.98816621 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M11.813724,6.98816621 C12.4243162,6.98816621 12.9192989,7.48314892 12.9192989,8.09374104 L12.9192989,11.8083855 C12.9192989,12.4189776 12.4243162,12.9139603 11.813724,12.9139603 L8.09907959,12.9139603 C7.48848747,12.9139603 6.99350476,12.4189776 6.99350476,11.8083855 L6.99350476,8.09374104 C6.99350476,7.48314892 7.48848747,6.98816621 8.09907959,6.98816621 L11.813724,6.98816621 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M4.82021928,6.98816621 C5.4308114,6.98816621 5.92579411,7.48314892 5.92579411,8.09374104 L5.92579411,11.8083855 C5.92579411,12.4189776 5.4308114,12.9139603 4.82021928,12.9139603 L1.10557483,12.9139603 C0.494982713,12.9139603 6.13829975e-13,12.4189776 6.13829975e-13,11.8083855 L6.13829975e-13,8.09374104 C6.13829975e-13,7.48314892 0.494982713,6.98816621 1.10557483,6.98816621 L4.82021928,6.98816621 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M18.8552758,13.9745529 C19.4658679,13.9745529 19.9608506,14.4695356 19.9608506,15.0801277 L19.9608506,18.7947722 C19.9608506,19.4053643 19.4658679,19.900347 18.8552758,19.900347 L15.1406313,19.900347 C14.5300392,19.900347 14.0350565,19.4053643 14.0350565,18.7947722 L14.0350565,15.0801277 C14.0350565,14.4695356 14.5300392,13.9745529 15.1406313,13.9745529 L18.8552758,13.9745529 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M11.813724,13.9745529 C12.4243162,13.9745529 12.9192989,14.4695356 12.9192989,15.0801277 L12.9192989,18.7947722 C12.9192989,19.4053643 12.4243162,19.900347 11.813724,19.900347 L8.09907959,19.900347 C7.48848747,19.900347 6.99350476,19.4053643 6.99350476,18.7947722 L6.99350476,15.0801277 C6.99350476,14.4695356 7.48848747,13.9745529 8.09907959,13.9745529 L11.813724,13.9745529 Z"
                                        id="Path"
                                    />
                                    <path
                                        d="M4.82021928,13.9745529 C5.4308114,13.9745529 5.92579411,14.4695356 5.92579411,15.0801277 L5.92579411,18.7947722 C5.92579411,19.4053643 5.4308114,19.900347 4.82021928,19.900347 L1.10557483,19.900347 C0.494982713,19.900347 6.13829975e-13,19.4053643 6.13829975e-13,18.7947722 L6.13829975e-13,15.0801277 C6.13829975e-13,14.4695356 0.494982713,13.9745529 1.10557483,13.9745529 L4.82021928,13.9745529 Z"
                                        id="Path"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Component.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Component.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Component;
