import React from 'react';
import PropTypes from 'prop-types';

const HomeFilled = (props) => {
    const { className, style } = props;

    return (
        <svg className={className} style={style} width="32px" height="32px" viewBox="0 0 32 32">
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="homeFilled" fill="currentColor" fillRule="nonzero">
                    <path
                        d="M31.137207,13.918457 C31.1364746,13.9177246 31.1357422,13.9169922 31.1350097,13.9162598 L18.081543,0.86328125 C17.5251465,0.306640625 16.7854004,0 15.9985351,0 C15.2116699,0 14.4719238,0.3063965 13.9152832,0.863037125 L0.868652375,13.9094238 C0.864257813,13.9138184 0.85986325,13.918457 0.85546875,13.9228516 C-0.287109375,15.0720215 -0.28515625,16.9365234 0.861084,18.0827637 C1.38476563,18.6066894 2.076416,18.9101562 2.815918,18.9418945 C2.84594725,18.9448242 2.87622069,18.9462891 2.90673825,18.9462891 L3.42700194,18.9462891 L3.42700194,28.5524902 C3.42700194,30.4533691 4.97363281,32 6.875,32 L11.9819336,32 C12.4995118,32 12.9194336,31.5803222 12.9194336,31.0625 L12.9194336,23.53125 C12.9194336,22.6638184 13.625,21.9582519 14.4924316,21.9582519 L17.5046387,21.9582519 C18.3720703,21.9582519 19.0776367,22.6638184 19.0776367,23.53125 L19.0776367,31.0625 C19.0776367,31.5803223 19.4973144,32 20.0151367,32 L25.1220703,32 C27.0234375,32 28.5700684,30.4533691 28.5700684,28.5524902 L28.5700684,18.9462891 L29.0524902,18.9462891 C29.8391113,18.9462891 30.5788574,18.6398926 31.1357422,18.0832519 C32.2832031,16.9350586 32.2836914,15.0673828 31.137207,13.918457 L31.137207,13.918457 Z"
                        id="Path"
                    />
                </g>
            </g>
        </svg>
    );
};

HomeFilled.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

HomeFilled.defaultProps = {
    className: undefined,
    style: undefined,
};

export default HomeFilled;
