import React from 'react';
import PropTypes from 'prop-types';

const SearchIcon = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            width="20px"
            height="20px"
            viewBox="0 0 20 20"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>search</title>
            <g id="layout" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="results" transform="translate(-1029.000000, -169.000000)" fillRule="nonzero">
                    <g id="Group-8" transform="translate(375.000000, 156.000000)">
                        <g id="Group-7" transform="translate(651.000000, 8.000000)">
                            <g id="search" transform="translate(3.000000, 5.000000)">
                                <path
                                    d="M12.8283333,13.107963 C12.7423715,13.1080631 12.6599208,13.0738697 12.5992593,13.012963 L11.7562963,12.1703704 C11.629731,12.0438051 11.629731,11.8386023 11.7562963,11.712037 C11.8828616,11.5854718 12.0880644,11.5854718 12.2146296,11.712037 L13.0575926,12.5555556 C13.1502089,12.6482372 13.1779037,12.7875701 13.1277713,12.9086256 C13.0776388,13.029681 12.959544,13.1086383 12.8285185,13.1087037 L12.8283333,13.107963 Z"
                                    id="Path"
                                    fill="#3E4343"
                                />
                                <path
                                    d="M7.03944444,-9.87146872e-14 C10.9026763,-9.87146872e-14 14.0344444,3.13176818 14.0344444,6.995 C14.0344444,10.8582318 10.9026763,13.99 7.03944444,13.99 C3.17621262,13.99 0.0444444444,10.8582318 0.0444444444,6.995 C0.0444444444,3.13176818 3.17621262,-9.87146872e-14 7.03944444,-9.87146872e-14 Z M7.03944444,1.20592593 C3.84222712,1.20592593 1.25037037,3.79778267 1.25037037,6.995 C1.25037037,10.1922173 3.84222712,12.7840741 7.03944444,12.7840741 C10.2366618,12.7840741 12.8285185,10.1922173 12.8285185,6.995 C12.8285185,3.79778267 10.2366618,1.20592593 7.03944444,1.20592593 Z"
                                    id="Combined-Shape"
                                    fill="#CCDBDB"
                                />
                                <circle
                                    id="Oval"
                                    fill="#C6E4ED"
                                    opacity="0.465076265"
                                    cx="7.03944444"
                                    cy="6.995"
                                    r="5.78907407"
                                />
                                <path
                                    d="M15.9727677,11.4806198 C16.6595937,11.4806198 17.2163762,12.0374023 17.2163762,12.7242283 L17.2163762,19.1397408 C17.2163762,19.8265668 16.6595937,20.3833493 15.9727677,20.3833493 C15.2859417,20.3833493 14.7291592,19.8265668 14.7291592,19.1397408 L14.7291592,12.7242283 C14.7291592,12.0374023 15.2859417,11.4806198 15.9727677,11.4806198 Z"
                                    id="Rectangle"
                                    fill="#3E4343"
                                    transform="translate(15.972768, 15.931985) rotate(-45.000000) translate(-15.972768, -15.931985) "
                                />
                                <path
                                    d="M13.1814815,12.537037 C13.2314093,12.7704515 13.3478204,12.9844293 13.5166667,13.1531481 L18.0537037,17.6901852 C18.4238458,18.0599345 18.9858956,18.1583229 19.4596296,17.9362963 C19.4097118,17.7028781 19.2932992,17.4888977 19.1244444,17.3201852 L14.5874074,12.7831481 C14.2172389,12.4134496 13.6552231,12.3150673 13.1814815,12.537037 L13.1814815,12.537037 Z"
                                    id="Path"
                                    fill="#525959"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

SearchIcon.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

SearchIcon.defaultProps = {
    className: undefined,
    style: undefined,
};

export default SearchIcon;
