import React from 'react';
import PropTypes from 'prop-types';

const NewCard = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            width="50px"
            height="36px"
            viewBox="0 0 50 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>add new</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="rainbow-UniversalPicker-"
                    transform="translate(-476.000000, -93.000000)"
                    fillRule="nonzero"
                >
                    <g id="add-new" transform="translate(477.000000, 94.200000)">
                        <path
                            d="M45.79875,33.6 L2.87730634,33.6 C1.28820423,33.6 1.66977543e-13,32.3105141 1.66977543e-13,30.7198532 L1.66977543e-13,2.88005166 C1.66977543e-13,1.28939073 1.28820423,1.81188398e-13 2.87730634,1.81188398e-13 L45.79875,1.81188398e-13 C47.3879472,1.81188398e-13 48.6760563,1.28948589 48.6760563,2.88005166 L48.6760563,30.7198532 C48.6760563,32.3105141 47.3878521,33.6 45.79875,33.6 Z"
                            id="Shape"
                            fill="#FBFBFC"
                        />
                        <path
                            d="M45.79875,-0.25 L2.87730634,-0.25 C1.15006551,-0.25 -0.25,1.15138703 -0.25,2.88005166 L-0.25,30.7198532 C-0.25,32.4485334 1.15008109,33.85 2.87730634,33.85 L45.79875,33.85 C47.5259753,33.85 48.9260563,32.4485334 48.9260563,30.7198532 L48.9260563,2.88005166 C48.9260563,1.15141901 47.5260228,-0.25 45.79875,-0.25 Z M2.87730634,0.25 L45.79875,0.25 C47.2497696,0.25 48.4260563,1.42745074 48.4260563,2.88005166 L48.4260563,30.7198532 C48.4260563,32.1725017 47.2497221,33.35 45.79875,33.35 L2.87730634,33.35 C1.42633423,33.35 0.25,32.1725017 0.25,30.7198532 L0.25,2.88005166 C0.25,1.42742386 1.42631354,0.25 2.87730634,0.25 Z"
                            id="border"
                            fill="#EDEFF2"
                        />
                        <path
                            d="M29.1428571,15.3178571 L24.8571429,15.3178571 L24.8571429,11.0321429 C24.8571429,10.559 24.4731429,10.175 24,10.175 C23.5268571,10.175 23.1428571,10.559 23.1428571,11.0321429 L23.1428571,15.3178571 L18.8571429,15.3178571 C18.384,15.3178571 18,15.7018571 18,16.175 C18,16.6481429 18.384,17.0321429 18.8571429,17.0321429 L23.1428571,17.0321429 L23.1428571,21.3178571 C23.1428571,21.791 23.5268571,22.175 24,22.175 C24.4731429,22.175 24.8571429,21.791 24.8571429,21.3178571 L24.8571429,17.0321429 L29.1428571,17.0321429 C29.616,17.0321429 30,16.6481429 30,16.175 C30,15.7018571 29.616,15.3178571 29.1428571,15.3178571 Z"
                            id="Path"
                            fill="#00AB8E"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

NewCard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

NewCard.defaultProps = {
    className: undefined,
    style: undefined,
};

export default NewCard;
