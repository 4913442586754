import React from 'react';
import PropTypes from 'prop-types';

const JCB = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            width="51px"
            height="36px"
            viewBox="0 0 51 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>jcb</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="rainbow-UniversalPicker-"
                    transform="translate(-359.000000, -495.000000)"
                    fillRule="nonzero"
                >
                    <g id="jcb" transform="translate(359.750000, 495.950000)">
                        <g id="dinners-copy">
                            <path
                                d="M46.04875,33.85 L3.12730634,33.85 C1.53820423,33.85 0.25,32.5605141 0.25,30.9698532 L0.25,3.13005166 C0.25,1.53939073 1.53820423,0.25 3.12730634,0.25 L46.04875,0.25 C47.6379472,0.25 48.9260563,1.53948589 48.9260563,3.13005166 L48.9260563,30.9698532 C48.9260563,32.5605141 47.6378521,33.85 46.04875,33.85 Z"
                                id="Shape"
                                fill="#FBFBFC"
                            />
                            <path
                                d="M46.04875,1.88293825e-13 L3.12730634,1.88293825e-13 C1.40006551,1.88293825e-13 1.70530257e-13,1.40138703 1.70530257e-13,3.13005166 L1.70530257e-13,30.9698532 C1.70530257e-13,32.6985334 1.40008109,34.1 3.12730634,34.1 L46.04875,34.1 C47.7759753,34.1 49.1760563,32.6985334 49.1760563,30.9698532 L49.1760563,3.13005166 C49.1760563,1.40141901 47.7760228,1.88293825e-13 46.04875,1.88293825e-13 Z M3.12730634,0.5 L46.04875,0.5 C47.4997696,0.5 48.6760563,1.67745074 48.6760563,3.13005166 L48.6760563,30.9698532 C48.6760563,32.4225017 47.4997221,33.6 46.04875,33.6 L3.12730634,33.6 C1.67633423,33.6 0.5,32.4225017 0.5,30.9698532 L0.5,3.13005166 C0.5,1.67742386 1.67631354,0.5 3.12730634,0.5 Z"
                                id="border"
                                fill="#EDEFF2"
                            />
                        </g>
                        <g id="Group-30" transform="translate(9.250000, 7.901000)">
                            <path
                                d="M1.39423868,19.9376132 L6.20617284,19.9376132 C7.898107,19.9376132 9.85432099,18.3516049 9.85432099,16.289465 L9.85432099,-6.7837825e-14 L5.20156379,-6.7837825e-14 C3.03382716,-6.7837825e-14 1.39423868,1.37440329 1.39423868,3.75407407 L1.39423868,19.9376132 Z"
                                id="Path"
                                fill="#0F549D"
                            />
                            <path
                                d="M10.9121811,19.9376132 L15.7241152,19.9376132 C17.4160494,19.9376132 19.3728395,18.3516049 19.3728395,16.289465 L19.3728395,-6.7837825e-14 L14.7195062,-6.7837825e-14 C12.5511111,-6.7837825e-14 10.9121811,1.37440329 10.9121811,3.75407407 L10.9121811,19.9376132 L10.9121811,19.9376132 Z"
                                id="Path"
                                fill="#B41F36"
                            />
                            <path
                                d="M20.5613169,19.9376132 L25.373251,19.9376132 C27.0651852,19.9376132 29.0219753,18.3516049 29.0219753,16.289465 L29.0219753,-6.7837825e-14 L24.3689712,-6.7837825e-14 C22.2012346,-6.7837825e-14 20.5616461,1.37440329 20.5616461,3.75407407 L20.5616461,19.9376132 L20.5613169,19.9376132 L20.5613169,19.9376132 L20.5613169,19.9376132 Z"
                                id="Path"
                                fill="#329947"
                            />
                            <g id="Group" transform="translate(0.000000, 6.843539)" fill="#F3F2F2">
                                <path
                                    d="M1.37481481,3.70271605 L0.0533333333,3.70271605 C0.423786008,5.18337449 2.16814815,6.34716049 4.12493827,6.34716049 C5.92279835,6.34716049 8.35514403,5.34197531 8.35514403,3.43843621 L8.35514403,0.00164609053 L5.34106996,0.00164609053 L5.34106996,3.43851852 C5.34106996,4.4437037 4.54773663,5.23670782 3.43720165,5.23670782 C2.43300412,5.2363786 1.53374486,4.60197531 1.37481481,3.70271605 Z"
                                    id="Path"
                                />
                                <path
                                    d="M27.7574486,4.39037037 C27.7574486,5.18304527 27.0706996,5.81777778 26.1714403,5.81777778 L19.5093004,5.81777778 L19.5093004,0.0012345679 L26.1714403,0.0012345679 C26.9641152,0.0012345679 27.652428,0.688888889 27.652428,1.48222222 C27.652428,2.27489712 26.9644444,2.96288066 26.1714403,2.96288066 C27.0706996,2.96271605 27.7574486,3.59736626 27.7574486,4.39037037 Z M22.0469136,2.6455144 L24.1093827,2.6455144 C24.6381893,2.6455144 25.1139918,2.16971193 25.1139918,1.64032922 C25.1139918,1.11218107 24.6381893,0.635720165 24.1093827,0.635720165 L22.0469136,0.635720165 L22.0469136,2.6455144 Z M24.1623045,5.18312757 C24.6904527,5.18312757 25.1669136,4.7073251 25.1669136,4.17851852 C25.1669136,3.64971193 24.690535,3.17390947 24.1623045,3.17390947 L22.0469136,3.17390947 L22.0469136,5.18279835 L24.1623045,5.18279835 L24.1623045,5.18312757 Z"
                                    id="Shape"
                                />
                                <path
                                    d="M17.9152263,4.81325103 L17.9152263,5.81786008 L12.8387654,5.81786008 C11.2527572,5.81786008 9.93069959,4.49604938 9.93069959,2.90979424 C9.93069959,1.32411523 11.2527572,0.00172839506 12.8387654,0.00172839506 L17.9152263,0.00172839506 L17.9152263,1.05958848 C16.6990947,0.795061728 15.5349794,0.636460905 14.9535802,0.636460905 C13.6851029,0.636460905 12.6804938,1.64106996 12.6804938,2.91012346 C12.6804938,4.17917695 13.6851029,5.18378601 14.9535802,5.18378601 C15.5349794,5.18312757 16.6990947,5.07777778 17.9152263,4.81325103 Z"
                                    id="Path"
                                />
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

JCB.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

JCB.defaultProps = {
    className: undefined,
    style: undefined,
};

export default JCB;
