import React from 'react';
import PropTypes from 'prop-types';

const Upload = (props) => {
    const { className, style } = props;
    return (
        <svg className={className} style={style} width="20px" height="18px" viewBox="0 0 494 459">
            <g
                id="column-customization"
                stroke="none"
                strokeWidth="1"
                fill="none"
                fillRule="evenodd"
            >
                <path
                    d="M24.5,211.15 C38.0751322,211.15 49,222.074868 49,235.65 L49,235.65 L49,360.45 C49,387.712207 71.212436,409.95 98.5,409.95 L98.5,409.95 L394.7,409.95 C421.962207,409.95 444.2,387.737564 444.2,360.45 L444.2,360.45 L444.2,237.65 C444.2,224.074868 455.124868,213.15 468.7,213.15 C482.253325,213.15 493.2,224.152812 493.2,237.65 L493.2,237.65 L493.2,360.45 C493.2,414.757721 449.042573,458.95 394.7,458.95 L394.7,458.95 L98.5,458.95 C44.1922793,458.95 0,414.792573 0,360.45 L0,360.45 L0,235.65 C0,222.074868 10.9248678,211.15 24.5,211.15 Z M246.6,0.25 C253.025032,0.25 259.319315,2.91296583 263.878175,7.47182541 L263.878175,7.47182541 L349.678175,93.2718254 C359.273942,102.867593 359.273942,118.332407 349.678175,127.928175 L349.678175,127.928175 L349.497833,128.111941 C344.902259,132.7071 338.807326,135.15 332.4,135.15 C326.013841,135.15 319.735884,132.542233 315.121825,127.928175 L315.121825,127.928175 L271.1,83.907 L271.1,331.25 C271.1,344.689381 260.392537,355.531238 247.006451,355.75 L247.006451,355.75 L246.6,355.75 C233.024868,355.75 222.1,344.825132 222.1,331.25 L222.1,331.25 L222.1,83.906 L178.078175,127.928175 C168.679303,137.327046 153.33461,137.422953 143.814395,128.215895 L143.814395,128.215895 L143.521825,127.928175 C133.926058,118.332407 133.926058,102.867593 143.521825,93.2718254 L143.521825,93.2718254 L229.321825,7.47182541 L229.485172,7.30491228 C234.027709,2.75302849 240.153673,0.25 246.6,0.25 Z"
                    id="upload"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
};

Upload.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Upload.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Upload;
