import React from 'react';
import PropTypes from 'prop-types';

const Github = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            fill="currentColor"
            width="32px"
            height="32px"
            viewBox="0 0 32 32"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>github</title>
            <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
                <g id="main" transform="translate(-1360.000000, -28.000000)">
                    <path
                        d="M1375.998,28.0209721 C1367.164,28.0209721 1360,35.356239 1360,44.4049721 C1360,51.6423267 1364.584,57.782247 1370.944,59.9505976 C1371.744,60.1015458 1372.036,59.5956653 1372.036,59.1611793 C1372.036,58.7715697 1372.022,57.7414502 1372.014,56.374757 C1367.564,57.3640797 1366.624,54.1778486 1366.624,54.1778486 C1365.898,52.2848765 1364.848,51.7810359 1364.848,51.7810359 C1363.394,50.7651952 1364.956,50.7855936 1364.956,50.7855936 C1366.562,50.9018645 1367.406,52.4745817 1367.406,52.4745817 C1368.834,54.9774661 1371.152,54.2553625 1372.064,53.8351554 C1372.208,52.7764781 1372.622,52.0543745 1373.08,51.6443665 C1369.528,51.2302789 1365.792,49.8248287 1365.792,43.548239 C1365.792,41.7592988 1366.416,40.2967331 1367.44,39.1503426 C1367.274,38.736255 1366.726,37.0697052 1367.596,34.8156813 C1367.596,34.8156813 1368.94,34.3750757 1371.996,36.4944701 C1373.272,36.1313785 1374.64,35.9498327 1376.002,35.9437131 C1377.36,35.9518725 1378.73,36.1313785 1380.008,36.49651 C1383.062,34.3771155 1384.404,34.8177211 1384.404,34.8177211 C1385.276,37.0737849 1384.728,38.7382948 1384.564,39.1523825 C1385.59,40.2987729 1386.208,41.7613386 1386.208,43.5502789 C1386.208,49.8431873 1382.468,51.228239 1378.904,51.6341673 C1379.478,52.1400478 1379.99,53.1395697 1379.99,54.6674104 C1379.99,56.8581992 1379.97,58.6247012 1379.97,59.1611793 C1379.97,59.599745 1380.258,60.1097052 1381.07,59.9485578 C1387.42,57.7781673 1392,51.6402869 1392,44.4049721 C1392,35.356239 1384.836,28.0209721 1375.998,28.0209721 Z"
                        id="github"
                    />
                </g>
            </g>
        </svg>
    );
};

Github.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Github.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Github;
