import React from 'react';
import PropTypes from 'prop-types';

const Mastercard = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            width="51px"
            height="36px"
            viewBox="0 0 51 36"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <title>master</title>
            <g id="modules" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="rainbow-UniversalPicker-"
                    transform="translate(-359.000000, -297.000000)"
                    fillRule="nonzero"
                >
                    <g id="master" transform="translate(359.750000, 297.950000)">
                        <path
                            d="M46.04875,33.85 L3.12730634,33.85 C1.53820423,33.85 0.25,32.5605141 0.25,30.9698532 L0.25,3.13005166 C0.25,1.53939073 1.53820423,0.25 3.12730634,0.25 L46.04875,0.25 C47.6379472,0.25 48.9260563,1.53948589 48.9260563,3.13005166 L48.9260563,30.9698532 C48.9260563,32.5605141 47.6378521,33.85 46.04875,33.85 Z"
                            id="Shape"
                            fill="#FBFBFC"
                        />
                        <path
                            d="M46.04875,1.88293825e-13 L3.12730634,1.88293825e-13 C1.40006551,1.88293825e-13 1.70530257e-13,1.40138703 1.70530257e-13,3.13005166 L1.70530257e-13,30.9698532 C1.70530257e-13,32.6985334 1.40008109,34.1 3.12730634,34.1 L46.04875,34.1 C47.7759753,34.1 49.1760563,32.6985334 49.1760563,30.9698532 L49.1760563,3.13005166 C49.1760563,1.40141901 47.7760228,1.88293825e-13 46.04875,1.88293825e-13 Z M3.12730634,0.5 L46.04875,0.5 C47.4997696,0.5 48.6760563,1.67745074 48.6760563,3.13005166 L48.6760563,30.9698532 C48.6760563,32.4225017 47.4997221,33.6 46.04875,33.6 L3.12730634,33.6 C1.67633423,33.6 0.5,32.4225017 0.5,30.9698532 L0.5,3.13005166 C0.5,1.67742386 1.67631354,0.5 3.12730634,0.5 Z"
                            id="border"
                            fill="#EDEFF2"
                        />
                        <g id="mastercard" transform="translate(9.250000, 7.050000)">
                            <path
                                d="M9.97940339,0 C4.47679927,0 0,4.47632576 0,9.97845642 C0,15.4805872 4.47679927,19.9569128 9.97940339,19.9569128 C15.4820076,19.9569128 19.9588068,15.4805872 19.9588068,9.97845642 C19.9588068,4.47632576 15.4820076,0 9.97940339,0 Z"
                                id="Path"
                                fill="#EE0005"
                            />
                            <path
                                d="M21.0508996,0 C15.5482955,0 11.0714962,4.47632576 11.0714962,9.97845642 C11.0714962,15.4805872 15.5482955,19.9569128 21.0508996,19.9569128 C26.5535038,19.9569128 31.030303,15.4805872 31.030303,9.97845642 C31.030303,4.47632576 26.5535038,0 21.0508996,0 L21.0508996,0 Z"
                                id="Path"
                                fill="#F9A000"
                            />
                            <path
                                d="M14.0492424,12.3439867 C14.5513731,12.3439867 14.9583333,12.750947 14.9583333,13.2530776 C14.9583333,13.7552084 14.5513731,14.1621685 14.0492424,14.1621685 L14.0492424,14.1621685 L11.9921875,14.1621685 C11.7239583,13.5838068 11.5097064,12.9756155 11.3555872,12.3439867 L11.3555872,12.3439867 Z M16.5501894,9.38565339 C17.0520833,9.38565339 17.4592803,9.79261364 17.4592803,10.2947443 C17.4592803,10.796875 17.0520833,11.2038352 16.5501894,11.2038352 L16.5501894,11.2038352 L11.1467803,11.2038352 C11.0973012,10.8020833 11.0714962,10.3932292 11.0714962,9.97845642 C11.0714962,9.77935606 11.0778882,9.58191285 11.0894886,9.38565339 L11.0894886,9.38565339 Z M14.0492424,6.42732006 C14.5513731,6.42732006 14.9583333,6.83451703 14.9583333,7.33641097 C14.9583333,7.8385417 14.5513731,8.24550188 14.0492424,8.24550188 L14.0492424,8.24550188 L11.2223012,8.24550188 C11.3328599,7.61742424 11.5026042,7.0094697 11.7251421,6.42732006 L11.7251421,6.42732006 Z"
                                id="Combined-Shape"
                                fill="#FF6300"
                            />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Mastercard.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Mastercard.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Mastercard;
