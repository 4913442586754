import React from 'react';
import PropTypes from 'prop-types';

const Warning = (props) => {
    const { className, style } = props;
    return (
        <svg
            className={className}
            style={style}
            width="30px"
            height="30px"
            viewBox="0 0 512 465"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <g id="components" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path
                    d="M256,142.55 C240.969886,142.55 229.133816,155.368591 230.328828,170.351528 L240.486838,297.669659 C241.132196,305.758735 247.884907,311.994 256,311.994 C264.115093,311.994 270.867804,305.758735 271.513163,297.669644 L281.671162,170.351659 C282.867387,155.369066 271.030487,142.55 256,142.55 Z M256,6.53699317e-13 C284.62,6.53699317e-13 309.587,15.573 322.939,38.696 L501.676,348.279 C508.231,359.632 512,372.794 512,386.845 C512,429.515 477.408,464.109 434.736,464.109 L77.264,464.109 C34.592,464.109 7.98650035e-12,429.515 7.98650035e-12,386.845 C7.98650035e-12,373.059113 3.62811534,360.128996 9.95499985,348.923548 L10.323,348.279 L189.061,38.696 C202.27948,15.80423 226.881822,0.312215 255.142504,0.004663877 L256,6.53699317e-13 Z M256,30.901 C239.670716,30.901 224.430144,39.6253874 216.086924,53.7009666 L215.823,54.152 L37.087,363.732 C33.049,370.737 30.906,378.731 30.906,386.849 C30.906,412.15241 51.2910999,432.790544 76.498674,433.200776 L77.264,433.207 L434.737,433.207 L435.502326,433.200777 C460.7099,432.790564 481.095,412.1534 481.095,386.849 C481.095,378.9565 479.069402,371.181207 475.245189,364.318021 L474.913,363.732 L296.177,54.152 L295.911924,53.6986451 C287.889411,40.1546882 273.47874,31.5723121 257.856059,30.9499592 L257.329,30.932 L256.6645,30.908625 L256,30.901 Z M256,332.554 C267.922723,332.554 277.588,342.219277 277.588,354.142 C277.588,366.064723 267.922723,375.73 256,375.73 C244.077277,375.73 234.412,366.064723 234.412,354.142 C234.412,342.219277 244.077277,332.554 256,332.554 Z"
                    id="warning"
                    fill="currentColor"
                    fillRule="nonzero"
                />
            </g>
        </svg>
    );
};

Warning.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};
Warning.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Warning;
