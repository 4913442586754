import React from 'react';
import PropTypes from 'prop-types';

const Billing = (props) => {
    const { className, style } = props;
    return (
        <svg
            width="21px"
            height="15px"
            viewBox="0 0 21 15"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
            style={style}
        >
            <title>billing</title>
            <g id="billing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g
                    id="functions/select-billing"
                    transform="translate(-1209.000000, -148.000000)"
                    fill="currentColor"
                    fillRule="nonzero"
                >
                    <path
                        d="M1229.54967,160.34074 C1229.54967,161.718832 1228.46121,162.807302 1227.08311,162.807302 L1227.08311,162.807302 L1211.46656,162.807302 C1210.08847,162.807302 1209,161.718846 1209,160.34074 L1209,160.34074 L1209,151.299601 C1209,149.92149 1210.08848,148.833 1211.46656,148.833 L1211.46656,148.833 L1227.08315,148.833 C1228.46124,148.833 1229.54975,149.921495 1229.54975,151.299601 L1229.54975,151.299601 Z M1228.726,154.735 L1209.823,154.735 L1209.82353,160.34074 C1209.82353,161.170211 1210.40446,161.835412 1211.19198,161.962023 L1211.34197,161.97936 L1211.46656,161.983773 L1227.08311,161.983773 C1228.00639,161.983773 1228.72614,161.264011 1228.72614,160.34074 L1228.72614,160.34074 L1228.726,154.735 Z M1220.09678,158.574057 C1220.42555,158.574057 1220.64474,158.793254 1220.64474,159.122024 C1220.64474,159.450794 1220.42555,159.66999 1220.09678,159.66999 L1212.4255,159.66999 C1212.09673,159.66999 1211.87754,159.450794 1211.87754,159.122024 C1211.87754,158.793254 1212.09673,158.574057 1212.4255,158.574057 L1220.09678,158.574057 Z M1216.80908,156.382294 C1217.13785,156.382294 1217.35705,156.601491 1217.35705,156.930261 C1217.35705,157.25903 1217.13785,157.478227 1216.80908,157.478227 L1212.4255,157.478227 C1212.09673,157.478176 1211.87754,157.258979 1211.87754,156.930209 C1211.87754,156.60144 1212.09673,156.382294 1212.4255,156.382294 L1216.80908,156.382294 Z M1227.08315,149.656529 L1211.46656,149.656529 C1210.5433,149.656529 1209.82353,150.37631 1209.82353,151.299601 L1209.82353,151.299601 L1209.82353,152.120529 L1228.726,152.120529 L1228.72622,151.299601 C1228.72622,150.418287 1228.07037,149.722385 1227.20774,149.660942 L1227.20774,149.660942 L1227.08315,149.656529 Z"
                        id="billing"
                    />
                </g>
            </g>
        </svg>
    );
};

Billing.propTypes = {
    className: PropTypes.string,
    style: PropTypes.object,
};

Billing.defaultProps = {
    className: undefined,
    style: undefined,
};

export default Billing;
